// Set current device width as a javascript variable. Availible alternatives: xs, sm, md, lg, xl.
// See _breakpoints.scss for more info.
// Use like this: console.log(breakpoint.value)

var breakpoint = {};
(function ($) {

    breakpoint.refreshValue = function () {
        this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
    };

    var updateBreakpointValueOnDebouncedResize = debounce(function () { // Throttles window resize events.
        breakpoint.refreshValue();
    }, 250);

    window.addEventListener('resize', updateBreakpointValueOnDebouncedResize);

    breakpoint.refreshValue();
})(jQuery);


function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

var Utilities = {

    constants: {
        $limitHeightBtn: $(".u-limitHeightBtn")
    },

    limitHeight: function () {
        var $limitHeightBtn = Utilities.constants.$limitHeightBtn,
            truncateClass = "is-truncated";

        if ($limitHeightBtn.length > 0) {
            $limitHeightBtn.each(function () {
                var $this = $(this),
                    height = $this.data("height"),
                    $textWrapper = $this.prev();

                if ($textWrapper.height() > height) {
                    $textWrapper.css("height", height - 20).addClass(truncateClass);
                    $this.removeClass("d-none");

                    $this.on("click", function () {
                        if ($textWrapper.hasClass(truncateClass)) {
                            $textWrapper.removeClass(truncateClass).css("height", "auto");
                            $this.text($this.data("less"));
                        }
                        else {
                            // Prevent "Show all" button being rendered if the container is just a couple of pixels to high.
                            // This way the expanded content will at least be 21 px higher than the truncated area. //SAMI
                            $textWrapper.addClass(truncateClass).css("height", (height - 20));
                            $this.text($this.data("more"));

                            var rect = $textWrapper[0].getBoundingClientRect();
                            if (rect.top < 0) {
                                $('html, body').scrollTop(Math.abs($textWrapper.offset().top - 100)); //Compensate for sticky header
                            }
                        }
                    })
                }
            });
        }
    },

    smoothScroll: {
        calculateOffset: function () { // Because sticky header
            var headerHeight = 0,
                mainNav = $(".MainNavigation"),
                productInPageNav = $(".ProductInPageNav");

            if (mainNav.length) {
                headerHeight += mainNav.outerHeight();
            }
            if (productInPageNav.length) {
                headerHeight += productInPageNav.outerHeight();
            }

            return headerHeight;
        },

        getElementY: function (query) {
            var offset = Utilities.smoothScroll.calculateOffset();
            return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top - offset;
        },

        doScrolling: function (element, duration) {
            var startingY = window.pageYOffset;
            var elementY = Utilities.smoothScroll.getElementY(element);
            // If element is close to page's bottom then window will scroll only to some position above the element.
            var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;
            var diff = targetY - startingY
            // Easing function: easeInOutCubic
            // From: https://gist.github.com/gre/1650294
            var easing = function (t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 };
            var start;

            if (!diff) return;

            // Bootstrap our animation - it will get called right before next frame shall be rendered.
            window.requestAnimationFrame(function step(timestamp) {
                if (!start) start = timestamp;
                // Elapsed miliseconds since start of scrolling.
                var time = timestamp - start;
                // Get percent of completion in range [0, 1].
                var percent = Math.min(time / duration, 1);
                // Apply the easing.
                // It can cause bad-looking slow frames in browser performance tool, so be careful.
                percent = easing(percent);

                window.scrollTo(0, startingY + diff * percent);

                // Proceed with animation as long as we wanted it to.
                if (time < duration) {
                    window.requestAnimationFrame(step);
                }
            })
        },

        bindClickEvents: function () {
            $("[data-smooth-scroll]").on("click", function (e) {
                var target = $(this).attr("href");
                Utilities.smoothScroll.doScrolling(target, 500);
                e.preventDefault();
            });
        }
    },

    // Scrolling to bookmarks smoothly
    navigateToBookmark: function () {
        navHeight = $(".MainNavigation").outerHeight();

        var h = window.location.hash;
        var splitUrl = h.split('=')[0];

        if (splitUrl.length) {
            if (!splitUrl.startsWith("#/")) {
                var target = $(splitUrl);

                if (target.length) {
                    $('html,body').animate({ scrollTop: target.offset().top - navHeight }, 400);
                }
            }
        }

        $(document).on('click', 'a', function (e) {
            var a = $(this),
                href = a.attr('href');

            if (Object.keys(a.data()).length === 0) {
                if (href && ~href.indexOf('#') && href.indexOf('#')===0) {
                    var name = href.substr(href.indexOf('#') + 1), target = $('a[name=' + name + ']'), target2 = $('#' + name);
                    target = target.length ? target : target2;

                    if (target.length) {
                        e.preventDefault();

                        // When sticky menu is not enabled
                        if (breakpoint.value === "xs" || breakpoint.value === "sm" || breakpoint.value === "md" || breakpoint.value === "lg") {
                            $('html,body').animate({
                                scrollTop: target.offset().top
                            }, 400);
                        }

                        // In desktop mode it is
                        else {
                            $('html,body').animate({
                                scrollTop: target.offset().top - navHeight
                            }, 400);
                        }
                    }
                }
            }
        });
    },

    init: function () {
        Utilities.limitHeight();
        //Utilities.smoothScroll.bindClickEvents();
        Utilities.navigateToBookmark();
    }

}

$(function () {
    Utilities.init();
});
